<template>
  <div>
    <cta-banner :title="$route.name"/>
    <cta-description class="md:py-36" :descripcion="headerDescripcion" :isImage="true" :patrocinar="true" />
    <cta-thematic :areas="areas" :header="headerAreas" />
  </div>
</template>

<script>
import CtaDescription from '../../components/CtaDescription.vue';
import CtaSubArea from '../../components/CtaSubArea.vue';
import CtaThematic from '../../components/CtaThematic.vue';
import CtaBanner from '../../components/common/CtaBanner.vue';
import API from "@/api";
import {mapGetters, mapState} from "vuex";

export default {
  name: "Objetivos",
  components: {
    CtaSubArea,
    CtaDescription,
    CtaThematic,
    CtaBanner
  },
  data() {
    return {
      descripciones: [],
      areas: [],
      headerDescripcion: {},
      headerAreas: {},
    };
  },
  created() {
    this.getInfo();
    this.getTematicas();
  },
  computed: {
    ...mapState(["idEventoActual"]),
    ...mapGetters(["eventoSeleccionado"])
  },
  methods: {
    async getInfo() {
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.headerDescripcion = (items[i].codigo === 'IN01') ? items[i] : this.headerDescripcion;
        this.headerDescripcion.cuerpo = this.eventoSeleccionado.cuerpo;
        this.headerDescripcion.titulo = "Objetivo";
        this.headerAreas = (items[i].codigo === 'IN04') ? items[i] : this.headerAreas;
      }
    },
    async getTematicas() {
      const {data} = await API.getTematicas({evento_fk: this.idEventoActual});
      this.areas = data.results.sort((a, b) => a.orden - b.orden);
    },
  },
};
</script>
